import { Component, OnInit, Renderer2 } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterOutlet } from '@angular/router';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { BaseView } from '../core/classes/baseview';
import { MessagesModule } from 'primeng/messages';
import { CommonModule } from '@angular/common';
import { ButtonsComponent } from '../core/components/buttons/buttons.component';
import { timer } from 'rxjs';
import { ConfigService } from '../core/service/config.service';
import { environment } from '../../environments/environment';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { versionNumber } from '../../environments/version';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, CommonModule, ButtonsComponent, ProgressBarModule, MessagesModule, CopyrightComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseView implements OnInit {
  override title = 'RemetricWeb';
  isbusy: boolean = false;

  constructor(private router: Router,
    private renderer: Renderer2) {
    super();
    this.isMaintainMode = ConfigService.isMaintenanceMode();
    this.addSubscription(this.router.events.subscribe(data => {
      if (data instanceof RouteConfigLoadStart) {
        this.getPageService().contentLoading();
        this.getPageService().setCompBusy('loading_application');
      } else if (data instanceof RouteConfigLoadEnd) {
        this.getPageService().contentLoaded();
        this.getPageService().setCompNotBusy();
      }
    }))

    this.addSubscription(timer(5000, 500)
      .subscribe(() => {
        this.getPageService().setHasScroll(document.documentElement.scrollHeight > window.innerHeight);
      }));
  }

  override ngOnInit(): void {
    console.log("Current Angular Version ", versionNumber);
    const analyticsId = environment.googleAnalyticsId;
    if (analyticsId) {
      const script = this.renderer.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
      this.renderer.appendChild(document.head, script);

      const inlineScript = this.renderer.createElement('script');
      inlineScript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${analyticsId}');
      `;
      this.renderer.appendChild(document.head, inlineScript);
    }
  }

  setBusy(isbusy: boolean) {
    this.isbusy = isbusy
  }

  isBusy() {
    return this.isbusy;
  }

  hasScroll() {
    return this.getPageService().hasScroll();
  }

  downScroll() {
    const element = document.getElementById('bottom-scroll');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  upScroll() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  pagedownScroll() {
    window.scrollBy({
      top: window.innerHeight - 180,
      behavior: 'smooth'
    });
  }

  pageupScroll() {
    window.scrollBy({
      top: -(window.innerHeight - 180),
      behavior: 'smooth'
    });
  }

  isMaintainMode: boolean = false;

  getMaintainTitle(): string {
    return ConfigService.getAllData("maintenanceTitle");
  }

  getMaintainMsg(): string {
    return ConfigService.getAllData("maintenanceMessage");
  }

  maintaincounter1: number = 0;
  maintaincounter2: number = 0;

  incrementcounter1(): void {
    this.maintaincounter1++;
    this.checkMaintainOff();
  }

  incrementcounter2(): void {
    this.maintaincounter2++;
    this.checkMaintainOff();
  }

  checkMaintainOff() {
    if (this.maintaincounter1 == 7 && this.maintaincounter2 == 7) {
      localStorage.setItem("maintenancemodeoverride", ConfigService.getAllData("maintenanceBypass"));
      this.reload();
    }
  }

  reload() {
    location.reload();
  }

}
