<ng-container *ngIf="!isMaintainMode">
    <router-outlet />
    <p-toast position="bottom-center" baseZIndex="900000"> </p-toast>

    <div class="arrow-nav" *ngIf="hasScroll()">
        <app-buttons (clicked)="upScroll()" [matchcolor]="true" iconclass="remetric-arrow-up-double" colorclass="btn btn-primary btn-medium" title="Submit"></app-buttons>
        <app-buttons (clicked)="pageupScroll()" [matchcolor]="true" iconclass="remetric-arrow-up-single" colorclass="btn btn-primary btn-medium" title="Submit"></app-buttons>
        <app-buttons (clicked)="pagedownScroll()" [matchcolor]="true" iconclass="remetric-arrow-down-single" colorclass="btn btn-primary btn-medium" title="Submit"></app-buttons>
        <app-buttons (clicked)="downScroll()" [matchcolor]="true" iconclass="remetric-arrow-down-double" colorclass="btn btn-primary btn-medium" title="Submit"></app-buttons>
    </div>
</ng-container>
<div class="login-wrapper" *ngIf="isMaintainMode">
    <div class="login-container">
        <div class="flex flex-row justify-content-center">
            <div class="flex login-main justify-content-center flex-column">
                <div class="pt-8 pb-3 px-8">
                    <div class="text-center logo-wrapper">
                        <img class="logo" src="./images/logo-login.svg" (click)="incrementcounter1()">
                    </div>
                    <div class="login-form flex flex-column text-center">

                        <h1>{{getMaintainTitle()}}</h1>
                        <p>{{getMaintainMsg()}}</p>
                        <div class="flex justify-content-center">
                            <app-buttons (clicked)="reload()" [matchcolor]="true" [showtitle]="true" [link]="true" colorclass="btn btn-secondry px-5" title="Retry"></app-buttons>
                        </div>

                    </div>
                    <div class="text-center copy-right-wrapper" (click)="incrementcounter2()">
                        <div class="copy-right">
                            <app-copyright></app-copyright>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>